.skip-link {
    margin-right: 1rem;
    position: absolute;
    transform: translateX(-200%);
    transition: transform 0.3s;
}

.skip-link:focus {
    position: static;
    transform: translateX(0);
}